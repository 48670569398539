/* eslint-disable no-underscore-dangle */

import questionJson from '@/json/it.json';
import { getKeyValue, saveKeyValue } from '@/script/util';

const savedItems = {
  __firstTimeStudying__: getKeyValue('firstTimeStudying', true),
  __lastTimeStudied__: getKeyValue('lastTimeStudied', Date.now()),
  __finishedStudying__: getKeyValue('finishedStudying', false),
  __questionLevels__: (function () {
    const time = getKeyValue('lastTimeStudied', Date.now());
    const defaultQuestionLevels = Object.fromEntries(Object.entries(questionJson).map(([key]) => [key, { level: 0, lastTimeStudied: time, firstTimeStudied: true }]));
    return getKeyValue('questionLevels', defaultQuestionLevels);
  }()),
  __questionLevelsProgressHistory__: getKeyValue('questionLevelsProgressHistory', [0, 0, 0, 479]),
  get firstTimeStudying() {
    return this.__firstTimeStudying__;
  },
  set firstTimeStudying(value = false) {
    this.__firstTimeStudying__ = value;
    saveKeyValue('firstTimeStudying', value);
  },
  get lastTimeStudied() {
    return this.__lastTimeStudied__;
  },
  set lastTimeStudied(value = Date.now()) {
    this.__lastTimeStudied__ = value;
    saveKeyValue('lastTimeStudied', value);
  },
  get finishedStudying() {
    return this.__finishedStudying__;
  },
  set finishedStudying(value = true) {
    this.__finishedStudying__ = value;
    saveKeyValue('finishedStudying', value);
  },
  get questionLevels() {
    return this.__questionLevels__;
  },
  set questionLevels({ key, correct }) {
    const time = Date.now();
    const questionLevel0 = this.__questionLevels__[key].level;

    this.lastTimeStudied = time;
    this.__questionLevels__[key].lastTimeStudied = time;

    if (this.__questionLevels__[key].firstTimeStudied) {
      this.__questionLevels__[key].firstTimeStudied = false;
      this.questionLevelsProgressHistory[3] -= 1;
    }

    if (questionLevel0 === 0) {
      if (correct) {
        this.__questionLevelsProgressHistory__[2] -= 1;
        this.__questionLevelsProgressHistory__[1] += 1;
      } else {
        this.__questionLevelsProgressHistory__[2] += 1;
        this.__questionLevelsProgressHistory__[1] -= 1;
      }
    }

    if (questionLevel0 === 2 && correct) {
      this.__questionLevelsProgressHistory__[1] -= 1;
      this.__questionLevelsProgressHistory__[0] += 1;
    }

    if (questionLevel0 === 3 && !correct) {
      this.__questionLevelsProgressHistory__[1] += 1;
      this.__questionLevelsProgressHistory__[0] -= 1;
    }

    if (questionLevel0 + correct < 3) {
      this.__questionLevels__[key].level += !correct;
    }

    saveKeyValue('questionLevels', this.__questionLevels__);
    saveKeyValue('questionLevelsProgressHistory', this.__questionLevelsProgressHistory__);
  },
  get questionLevelsProgressHistory() {
    return this.__questionLevelsProgressHistory__;
  },
};

function questionSorter(listOfKeyFiltered) {
  const { questionLevels } = savedItems;
  const time = Date.now();
  return listOfKeyFiltered.sort((A, B) => questionLevels[A].level * (time - questionLevels[A].lastTimeStudied)
  - questionLevels[B].level * (time - questionLevels[B].lastTimeStudied));
}

function getListOfKeys() {
  const { questionLevels } = savedItems;
  return function* () {
    while (Object.keys(questionLevels).filter((key) => questionLevels[key].level !== 3).length !== 0) {
      yield questionSorter(Object.keys(questionLevels).filter((key) => questionLevels[key].level !== 3)).slice(0, 20).shuffleArray()[0];
    }
  };
}

export {
  getListOfKeys, savedItems,
};
