import Vue from 'vue';
import { savedItems } from '@/script/data';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  data() {
    return savedItems;
  },
}).$mount('#app');
