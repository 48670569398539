/* eslint no-extend-native: ["error", { "exceptions": ["Array"] }] */

function shuffleArray() {
  const arrayOut = this;
  for (let i = arrayOut.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayOut[i], arrayOut[j]] = [arrayOut[j], arrayOut[i]];
  }
  return arrayOut;
}
Object.defineProperty(Array.prototype, 'shuffleArray', {
  value: shuffleArray,
});

function saveKeyValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getKeyValue(key, defaultValue) {
  let value = JSON.parse(window.localStorage.getItem(key));
  if (value === null || value === undefined) {
    value = defaultValue;
    saveKeyValue(key, value);
  }
  return value;
}

export {
  getKeyValue, saveKeyValue,
};
